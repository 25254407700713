body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  text-align: center;
}

.Content_background {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  text-align: center;
}

.content {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/****** HOME PAGE ******/
.home {
  margin-top: 30%;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.home .loader {
  align-self: center;
  width: 100%;
  text-align: center;
}
.home .error {
  width: 100%;
  align-self: center;
  color: #f42b4b;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.home > .card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 400px;
  width: 30%;
  position: relative;
  margin-bottom: 2%;
}
/* On mouse-over, add a deeper shadow */
.home .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.home .card > img {
  width: 100%;
  height: 100%;
}
.home .content {
  bottom: 0;
  z-index: 9;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 35%;
  padding-bottom: 5px;
  transition: 0.5s;
}
.home .content:hover {
  background-color: rgba(255, 255, 255, 1);
  height: 50%;
  cursor: pointer;
}
.content > h2 {
  text-align: center;
  font-size: 2rem;
}
@media screen and (max-width: 780px) {
  .home {
    justify-content: space-around;
  }
  .home .card {
    width: 45%;
  }
}
@media screen and (max-width: 500px) {
  .home .card {
    width: 90%;
  }
}
@media screen and (min-width: 1400px) {
  .home {
    margin: auto;
    width: 1400px;
  }
  .toggle-button {
    margin-bottom: 10px;
  }
}
/****** HOME PAGE ******/

/** Librerias Custom **/

/** Titulos de pagina **/

/* .main_title {
   padding-top: 15px;
   padding-bottom: 15px;
   width: 100%;
   height: 48px;
   font-family: Arial, Helvetica, sans-serif;
   font-size: 14px;
   font-weight: 900;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.71;
   letter-spacing: normal;
   color: #333333;
 } */

/** Layout de paginas principales **/

.layout {
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

/** Footer personalizado **/
.footer {
  clear: both;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #282c34;
  color: white;
  text-align: center;
}

/** Botones **/

.button_primary {
  color: #ffffff;
  width: auto;
  height: 35px;
  background-color: #c6cbd4;
  border: solid 2px #c6cbd4;
}

.button_primary:hover {
  color: #333333;
  width: auto;
  height: 35px;
  background-color: #ffffff;
  border: solid 2px #c6cbd4;
}

.button_secondary {
  color: #333333;
  width: auto;
  height: 35px;
  background-color: #ffffff;
  border: solid 2px #c6cbd4;
}

.button_secondary:hover {
  color: #333333;
  width: auto;
  height: 35px;
  background-color: #ffffff;
  border: solid 2px #c6cbd4;
}

.button_primary_modal {
  color: #ffffff;
  width: auto;
  height: 35px;
  background-color: #c6cbd4;
  border: solid 2px #c6cbd4;
}

.button_primary_modal:hover {
  color: #333333;
  width: auto;
  height: 35px;
  background-color: #ffffff;
  border: solid 2px #c6cbd4;
}

.button_dropdown {
  color: #333333;
  width: auto;
  height: 35px;
  border: solid 1px #c6cbd4;
}

.button_dropdown:hover {
  color: #333333;
  width: auto;
  height: 35px;
  border: solid 1px #c6cbd4;
}

#components-dropdown-demo-dropdown-button .ant-dropdown-button {
  text-align: center;
  margin: 0 8px 8px 0;
}

.input_form {
  width: 336px;
  height: 40px;
  background-color: #f0f3f6;
}

/** Breadcrumb **/

.gti_breadcrumb {
  margin-top: 10px;
  margin: 14px;
}
.gti_breadcrumb_nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #ffffff;
}
.gti_breadcrumb_nav a {
  color: #000000;
  line-height: 30px;
  padding: 0 8px;
}
.app-list {
  margin-top: 16px;
}

/** Botones **/

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 100px;
  margin-left: 300px;
}

/* use css to set position of modal */
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}

.vertical-center-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

.modal_body {
  text-align: center;
  padding-top: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

/** Fin del modal **/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
}

.login_body {
  background: #bcbec9 !important;
  justify-content: center;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.login_body,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  height: 680px;
  width: 532px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.forgot-password a {
  color: #167bff;
}

.card_deck {
  padding-bottom: 100px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

